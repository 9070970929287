/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409101919-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqContentApiClass {
 
   constructor() {}

  /**
   * @summary Archive an asset selection
   * @param {string} id - The Seeq ID for the asset selection
   */
  public archiveAssetSelection(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/content/asset-selections/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Archive a content item
   * @param {string} id - The Seeq ID for the content
   */
  public archiveContent(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/content/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Archive a date range
   * @param {string} id - The Seeq ID for the date range
   */
  public archiveDateRange(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/content/date-ranges/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Clear the cached images for a piece of content
   * @param {string} id - The Seeq ID for the content
   */
  public clearImageCache(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/content/${encodeURIComponent(String(id))}/image`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Create new asset selection
   */
  public createAssetSelection(
    body: models.AssetSelectionInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/content/asset-selections`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetSelectionOutputV1>;
  }

  /**
   * @summary Create a new content item
   */
  public createContent(
    body: models.ContentInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/content`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContentOutputV1>;
  }

  /**
   * @summary Create a new date range
   */
  public createDateRange(
    body: models.DateRangeInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/content/date-ranges`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DateRangeOutputV1>;
  }

  /**
   * @summary Get an asset selection
   * @param {string} id - The Seeq ID for the asset selection
   */
  public getAssetSelection(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/asset-selections/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetSelectionOutputV1>;
  }

  /**
   * @summary Get a content item
   * @param {string} id - The Seeq ID for the content
   */
  public getContent(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContentOutputV1>;
  }

  /**
   * @summary Get content items
   * @param {number} [offset=0] - The pagination offset, the index of the first content item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of content items that will be returned in this page of results
   */
  public getContentItems(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetContentItemsOutputV1>;
  }

  /**
   * @summary Get the content items that depend on a given date range
   * @param {string} id - The Seeq ID for the date range
   * @param {number} [offset=0] - The pagination offset, the index of the first content item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of content items that will be returned in this page of results
   */
  public getContentItemsForDateRange(
    {
      id,
      offset,
      limit
    } : {
      id: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/date-ranges/${encodeURIComponent(String(id))}/content`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetContentItemsOutputV1>;
  }

  /**
   * @summary Retrieves all pieces of content and date ranges associated with the given report with extra metadata attached
   * @param {string} reportId - Report to retrieve content and date ranges for
   * @param {boolean} [includeWorkstepBlobs=false] - Set to true to include the source and workstep blobs in the output
   */
  public getContentsWithAllMetadata(
    {
      reportId,
      includeWorkstepBlobs
    } : {
      reportId: string,
      includeWorkstepBlobs?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(reportId)) {
      throw new Error("'reportId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/report/${encodeURIComponent(String(reportId))}`,

      params: omitBy({
        ['includeWorkstepBlobs']: includeWorkstepBlobs
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContentWithMetadataListOutputV1>;
  }

  /**
   * @summary Get a date range
   * @param {string} id - The Seeq ID for the date range
   */
  public getDateRange(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/date-ranges/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DateRangeOutputV1>;
  }

  /**
   * @summary Get date range items
   * @param {number} [offset=0] - The pagination offset, the index of the first date range that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of date ranges that will be returned in this page of results
   */
  public getDateRanges(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/date-ranges`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetDateRangesOutputV1>;
  }

  /**
   * @summary Get the image from a piece of content
   * @param {string} id - The Seeq ID for the content
   * @param {boolean} [useAsync=false] - When true, if the image is not cached a one pixel image will be returned from this call and a job will be started to generate the new image. Used to avoid blocking requests when called from a browser context.
   */
  public getImage(
    {
      id,
      useAsync
    } : {
      id: string,
      useAsync?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/${encodeURIComponent(String(id))}/image`,

      params: omitBy({
        ['useAsync']: useAsync
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Retrieves all reports that have notifications configured
   * @param {Array<string>} [ownerIds]
   * @param {string} [nameSearch] - Filter the reports to those that are part of a topic document with the provided name
   * @param {number} [offset=0] - The pagination offset, the index of the first reports that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of reports that will be returned in this page of results
   * @param {boolean} [sortAsc=true] - Sorts the document name in ascending order when true and descending when false
   */
  public getNotifiableReports(
    {
      ownerIds,
      nameSearch,
      offset,
      limit,
      sortAsc
    } : {
      ownerIds?: Array<string>,
      nameSearch?: string,
      offset?: number,
      limit?: number,
      sortAsc?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/notifiableReports`,

      params: omitBy({
        ['ownerIds']: ownerIds,
        ['nameSearch']: nameSearch,
        ['offset']: offset,
        ['limit']: limit,
        ['sortAsc']: sortAsc
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NotifiableReportOutputListV1>;
  }

  /**
   * @summary Get the react json blob for a piece of content
   * @param {string} id - The Seeq ID for the content
   */
  public getReactBlob(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/${encodeURIComponent(String(id))}/react`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Gets a collection of statistics for scheduled items
   * @param {Array<string>} types - List of types to filter on
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {string} [sortBy=AverageRunTime] - A field by which to order the items&#x27;. Options: AverageRunTime, TotalRunTime, Name, Username, ContentCount, LastViewedAt, NextRunTime. Note: sorting by NextRunTime may be significantly slower than other options.
   * @param {boolean} [sortAsc=true] - If true or blank, sorts ascending. If false sorts descending.
   * @param {boolean} [isEnabled=true] - If true, includes only items with enabled schedules. If false, includes only items with disabled schedules.
   * @param {string} [nameFilter] - The topic/worksheet name to filter on.
   * @param {string} [ownerFilter] - The owner username to filter on.
   * @param {string} [scheduleFilter] - The schedule text to filter on.
   */
  public getSchedulableStatistics(
    {
      types,
      offset,
      limit,
      sortBy,
      sortAsc,
      isEnabled,
      nameFilter,
      ownerFilter,
      scheduleFilter
    } : {
      types: TypesEnum[],
      offset?: number,
      limit?: number,
      sortBy?: SortByEnum,
      sortAsc?: boolean,
      isEnabled?: boolean,
      nameFilter?: string,
      ownerFilter?: string,
      scheduleFilter?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(types)) {
      throw new Error("'types' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/schedulable/metrics`,

      params: omitBy({
        ['types']: types,
        ['offset']: offset,
        ['limit']: limit,
        ['sortBy']: sortBy,
        ['sortAsc']: sortAsc,
        ['isEnabled']: isEnabled,
        ['nameFilter']: nameFilter,
        ['ownerFilter']: ownerFilter,
        ['scheduleFilter']: scheduleFilter
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SchedulableAdminListOutputV1>;
  }

  /**
   * @summary Gets a summary of statistics for scheduled items across a week, with each day being separated into 24 buckets
   * @param {string} start - The ISO Date string to start the summary from. The time must start at midnight on some day
   * @param {string} [stat=AverageRunTime] - The stat to summarize by
   * @param {string} [ownerFilter] - The owner username to filter on.
   */
  public getSchedulableSummary(
    {
      start,
      stat,
      ownerFilter
    } : {
      start: string,
      stat?: StatEnum,
      ownerFilter?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/schedulable/metrics/summary`,

      params: omitBy({
        ['stat']: stat,
        ['start']: start,
        ['ownerFilter']: ownerFilter
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SchedulableSummaryWeekOutputV1>;
  }

  /**
   * @summary Preview an image for given content parameters
   * @param {string} worksheetId - The content&#x27;s source worksheet
   * @param {string} workstepId - The content&#x27;s source workstep
   * @param {number} height - The content&#x27;s desired height
   * @param {number} width - The content&#x27;s desired width
   * @param {number} [scale=1.0] - The content&#x27;s desired scale
   * @param {string} [timezone] - The content&#x27;s desired timezone
   * @param {string} [selector] - The content&#x27;s desired selector
   * @param {string} [dateRangeId] - The content&#x27;s date range; mutually exclusive with dateRangeFormula
   * @param {string} [dateRangeFormula] - Formula used as a temporary date range; mutually exclusive with dateRangeId
   * @param {string} [validityDuration] - A duration to use as the Expires header
   * @param {string} [reportId] - The content&#x27;s report
   * @param {string} [contentId] - The content&#x27;s ID
   * @param {string} [summaryType] - The content&#x27;s desired summary type
   * @param {string} [summaryValue] - The content&#x27;s desired summary value
   * @param {string} [assetSelectionId] - The content&#x27;s asset selection id
   * @param {boolean} [react] - Whether the content is for a react component
   * @param {boolean} [hideUncertainty] - Whether uncertainty indicators should be hidden
   */
  public previewContent(
    {
      worksheetId,
      workstepId,
      height,
      width,
      scale,
      timezone,
      selector,
      dateRangeId,
      dateRangeFormula,
      validityDuration,
      reportId,
      contentId,
      summaryType,
      summaryValue,
      assetSelectionId,
      react,
      hideUncertainty
    } : {
      worksheetId: string,
      workstepId: string,
      height: number,
      width: number,
      scale?: number,
      timezone?: string,
      selector?: string,
      dateRangeId?: string,
      dateRangeFormula?: string,
      validityDuration?: string,
      reportId?: string,
      contentId?: string,
      summaryType?: SummaryTypeEnum,
      summaryValue?: string,
      assetSelectionId?: string,
      react?: boolean,
      hideUncertainty?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(workstepId)) {
      throw new Error("'workstepId' parameter required");
    }

    if (isNil(height)) {
      throw new Error("'height' parameter required");
    }

    if (isNil(width)) {
      throw new Error("'width' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/preview`,

      params: omitBy({
        ['worksheetId']: worksheetId,
        ['workstepId']: workstepId,
        ['height']: height,
        ['width']: width,
        ['scale']: scale,
        ['timezone']: timezone,
        ['selector']: selector,
        ['dateRangeId']: dateRangeId,
        ['dateRangeFormula']: dateRangeFormula,
        ['validityDuration']: validityDuration,
        ['reportId']: reportId,
        ['contentId']: contentId,
        ['summaryType']: summaryType,
        ['summaryValue']: summaryValue,
        ['assetSelectionId']: assetSelectionId,
        ['react']: react,
        ['hideUncertainty']: hideUncertainty
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Redirect to the source worksheet for a given content image
   * @param {string} id - The Seeq ID for the content
   * @param {boolean} [noRedirect=false] - Set to true to receive the redirected URL as data rather than redirecting
   */
  public redirectToSourceWorksheet(
    {
      id,
      noRedirect
    } : {
      id: string,
      noRedirect?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/content/${encodeURIComponent(String(id))}/sourceUrl`,

      params: omitBy({
        ['noRedirect']: noRedirect
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Update an asset selection
   * @param {string} id - The Seeq ID for the asset selection
   */
  public updateAssetSelection(
    body: models.AssetSelectionInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/content/asset-selections/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetSelectionOutputV1>;
  }

  /**
   * @summary Update a content item
   * @param {string} id - The Seeq ID for the content
   * @param {boolean} [clearCache=false] - Whether the content&#x27;s cache should be cleared. Useful to force regeneration of an image when content parameters have not changed
   */
  public updateContent(
    body: models.ContentInputV1,
    {
      id,
      clearCache
    } : {
      id: string,
      clearCache?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/content/${encodeURIComponent(String(id))}`,
      data: body,

      params: omitBy({
        ['clearCache']: clearCache
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContentOutputV1>;
  }

  /**
   * @summary Update a date range
   * @param {string} id - The Seeq ID for the date range
   */
  public updateDateRange(
    body: models.DateRangeInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/content/date-ranges/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DateRangeOutputV1>;
  }

  /**
   * @summary Update the report's last viewed time
   * @param {string} id - The Seeq ID for the report
   * @param {string} [viewType=NORMAL] - The type of view being tracked
   */
  public updateLastViewedTime(
    {
      id,
      viewType
    } : {
      id: string,
      viewType?: ViewTypeEnum
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/content/reports/lastViewed/${encodeURIComponent(String(id))}`,

      params: omitBy({
        ['viewType']: viewType
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

}

export enum TypesEnum {
    Scheduled = 'Scheduled' as any,
    Live = 'Live' as any,
    Static = 'Static' as any,
    ConditionMonitor = 'ConditionMonitor' as any
}
export enum SortByEnum {
    AverageRunTime = 'AverageRunTime' as any,
    TotalRunTime = 'TotalRunTime' as any,
    Name = 'Name' as any,
    Owner = 'Owner' as any,
    ContentCount = 'ContentCount' as any,
    LastViewedAt = 'LastViewedAt' as any,
    NextRunTime = 'NextRunTime' as any
}
export enum StatEnum {
    AverageRunTime = 'AverageRunTime' as any
}
export enum SummaryTypeEnum {
    DISCRETE = 'DISCRETE' as any,
    AUTO = 'AUTO' as any,
    NONE = 'NONE' as any
}
export enum ViewTypeEnum {
    NORMAL = 'NORMAL' as any,
    FIRSTTIME = 'FIRST_TIME' as any,
    PDF = 'PDF' as any
}

export const sqContentApi = new sqContentApiClass();
